"use client"

import { useEffect, useRef, useState } from 'react'
import Icon from '@components/shared/Icon'
import useMediaQueryBreakpoint from '@hooks/useMediaQueryBreakpoint'
import useWindowWidth from '@hooks/useWindowWidth'
import mix from '@utils/styles/mix'

import styles from './SimpleScrollSlider.module.css'

function SimpleScrollSlider ({ children, small }) {
    const [gridOverflows, confirmGridOverflow] = useState()
    const [leftControl, showLeftControl] = useState(false)
    const [rightControl, showRightControl] = useState(true)

    const ref = useRef()
    const onMobile = useMediaQueryBreakpoint(1380)

    const width = useWindowWidth()

    const scrollTo = (direction, e) => {
        if (e) e.stopPropagation()

        const grid = ref.current
        const childWidth = grid.firstChild.offsetWidth
        const scrollDelta = childWidth * direction
        grid.scrollLeft += scrollDelta
    }

    const scrollLeft = (e) => scrollTo(-1, e)
    const scrollRight = (e) => scrollTo(1, e)

    useEffect(() => {
        const _width = Math.min(1322 + 40, width) // 1322 -> new container width --n-container-wdt global.css
        confirmGridOverflow(ref.current?.scrollWidth > _width)
    }, [gridOverflows, width])

    useEffect(() => {
        const grid = ref.current
        if (!grid) return

        const determineControlAvailability = () => {
            const errorTreshold = 25
            showLeftControl(grid.scrollLeft > errorTreshold)
            showRightControl(grid.scrollLeft + grid.offsetWidth < (grid.scrollWidth - errorTreshold))
        }

        grid.addEventListener('scrollend', determineControlAvailability)

        return () => grid.removeEventListener('scrollend', determineControlAvailability)
    }, [])

    return (
        <div style={{position:'relative'}}>
            <div className={mix([styles.slider, small && styles.small])} ref={ref}>
                {children}
            </div>

            {!onMobile && gridOverflows && <>
                { leftControl && <span className={mix([styles.control, styles.left, 'clickable'])} onClick={scrollLeft}>
                    <Icon>chevron_left</Icon></span> }

                { rightControl && <span className={mix([styles.control, styles.right, 'clickable'])} onClick={scrollRight}>
                    <Icon>chevron_right</Icon>
                </span> }
            </>}
        </div>
    )
}

function Slide ({ children, small = false }) {
    return <div className={mix([styles.slide, small && styles.small])}>
        {children}
    </div>
}

SimpleScrollSlider.Slide = Slide

export default SimpleScrollSlider
export {
    Slide,
    SimpleScrollSlider,
}