"use client"

import Button from '@components/button/Button'
import Icon from '@components/shared/Icon'
import useLocalStorage from '@hooks/useLocalStorage'
import useT from '@hooks/useTranslation'

export function MoreOptionsButton () {
    const [,setValue] = useLocalStorage('filtry', '')

    const t = {
		wiecej: useT('Więcej opcji'),
	}

    const onClick = () => {
        addEventListener('storage', () => {
            const search = document.querySelector('[type="submit"]')
            if (search) search.click()
        }, { once: true })
        setValue('1')
    }

    return (
        <Button
            onClick={onClick}
            style={{height:32,margin:'auto',padding:0}} white>
                <Icon style={{color:'var(--teal)',margin:0}}>tune</Icon> {t.wiecej}
        </Button>
    )
}