"use client"

import { useEffect } from 'react'
import Maybe from '@components/layout/Maybe'

let viewed = false

const rootDir = '/baners/raiffeisen'
const entryPoint = 'index.html'
const mobileSrc = [rootDir,'mobile',entryPoint].join('/')
const desktopSrc = [rootDir,'desktop',entryPoint].join('/')

const STYLE_DESKTOP = {
    aspectRatio: 95/20,
    border: 'none',
    overflow: 'hidden',
    width: '100%',
}

const STYLE_MOBILE = {
    aspectRatio: 4/3,
    border: 'none',
    overflow: 'hidden',
    width: '100%',
}

function RaiffeisenBaner ({ offer, showsOn = ['mobile', 'desktop'] }) {
    const shows = offer ? doShowRaiffeisen(offer) : true
    if (!shows) return null

    const displayMobile = showsOn.includes('mobile')
    const displayDesktop = showsOn.includes('desktop')

    return <>
        <Maybe.Mobile>
            { displayMobile && <>
                <iframe scrolling="no" src={mobileSrc} style={STYLE_MOBILE} />
                <ViewTag />
            </> }
        </Maybe.Mobile>
        <Maybe.Desktop>
            { displayDesktop && <>
                <br/>
                <iframe scrolling="no" src={desktopSrc} style={STYLE_DESKTOP} />
                <ViewTag />
            </> }
        </Maybe.Desktop>

    </>
}

function ViewTag () {
    useEffect(() => {
        if (viewed) return

        const url = "https://ad.doubleclick.net/ddm/trackimp/N9439.5336216SAMOCHODYPL/B31792876.409683665;dc_trk_aid=601804480;dc_trk_cid=221270964;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?"
            .replace('[timestamp]', Date.now())

        fetch(url, { mode: 'no-cors' })
        .then((_) => {
            viewed = true
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])
    return null
}

function doShowRaiffeisen (offer) {
    const { price, type } = offer || {}
    const { price: p = 0 } = price || {}
    return ['car_ad','motorcycle_ad'].includes(type) && p >= 5000 && p <= 150000
}

export {
    RaiffeisenBaner,
    doShowRaiffeisen,
}