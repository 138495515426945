"use client"

import { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from "next/legacy/image"
import MobileImage from '@components/image/Mobile100Width'
import useT from '@hooks/useTranslation'
import useWindowWidth from '@hooks/useWindowWidth'
import mix from '@utils/styles/mix'

import styles from 'styles/ChangingAdBaner.module.css'

const ChangingAdBanerGridWrapper = ({ children }) => {
    return <div className={styles.grid}>
        {children}
    </div>
}

const ChangingAdBanner = ({ alwaysDesktop, alwaysMobile, data, long, nofollow, style = { height: 200, margin: '30px auto' }, time = 10000 }) => {
    const [active, setActive] = useState(0)
    const width = useWindowWidth()
    const onMobile = width <= 1024

    useEffect(() => {
        if (data.length < 2) return

        const id = setTimeout(
            () => setActive((active + 1) % data.length),
            time
        )

        return () => clearTimeout(id)
    }, [active, data.length, time])

    const noData = data.length < 1
    if (noData && long) return null
    if (noData) return <Empty style={style} />

    const { link, photo, mobilePhoto, project } = data[active]

    const mobileCondition = alwaysDesktop ? false : onMobile || alwaysMobile
    const imageStyle = mobileCondition ? { ...style, height: 300, background: '#fff' } : style
    const imageSrc = mobileCondition ? mobilePhoto : photo
    const imageFit = mobileCondition ? "contain" : "cover"

    return (
        <Link
            href={link}
            prefetch={false}
            target="_blank"
            rel={mix(["noopener noreferrer", nofollow && 'nofollow'])}
        >

                {
                    long
                        ? <div style={{background:'white',marginTop:30}}>
                            <MobileImage alt={project} src={imageSrc} />
                          </div>

                        : <div className={styles.wrapper} style={imageStyle}>
                                <Image
                                    src={imageSrc}
                                    alt={project}
                                    layout="fill"
                                    objectFit={imageFit}
                                />
                          </div>
                }

            </Link>
    );
}

function Empty ({ style = {} }) {
    return (
        <div className={styles.wrapper} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...style }}>
            <p style={{ fontSize: '30rem', fontWeight: 'bold', color: 'var(--input-border-c)', textAlign: 'center', padding: '0 20px' }}>{useT('Miejsce na Twoją reklamę')}</p>
        </div>
    )
}



export default ChangingAdBanner
export {
    ChangingAdBanerGridWrapper,
}