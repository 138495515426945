"use client"

import { memo, useEffect } from 'react'
import Link from 'next/link'
import useMediaQueryBreakpoint from '@hooks/useMediaQueryBreakpoint'
import mix from '@utils/styles/mix'

import styles from './UniqaAd.module.css'

const TrackingImgMemoized = memo(TrackingImage)

function UniqaAd({
	alt = 'Uniqa',
	breakpoint,
	desktop,
	desktopImgSrc,
	forceMobile,
	href,
	mobileImgSrc,
	nolink,
	target = '_blank',
	viewImgSrc,
	...rest
}) {
	const onMobile = useMediaQueryBreakpoint(breakpoint || 800)
	const src = desktop
		? desktopImgSrc
		: forceMobile || onMobile
			? mobileImgSrc
			: desktopImgSrc

	const _href = nolink ? '' : href

	const className = mix([styles.a, nolink && styles.disabled])

	return (
        (<Link href={_href} className={className} target={target} {...rest}>
            <img alt={alt} src={src} />
            { viewImgSrc && <TrackingImgMemoized key={viewImgSrc} src={viewImgSrc} /> }

        </Link>)
    );
}

let lastView
function TrackingImage({ src }) {
	useEffect(() => {
		if (lastView == src) return
		lastView = src

		const imgSrc = src.replace('[timestamp]', Date.now())

		fetch(imgSrc, { mode: 'no-cors' })
			.then((_) => null)
			.catch((err) => console.log(err))
	}, [src])

	return null
}

export { UniqaAd }
