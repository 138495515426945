import { useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'

const _style = (loading, additionals = {}) => ({
    display: 'block',
    height: 'auto',
    opacity: (loading ? 0 : 1),
    width: '100%',
    ...additionals
})

export default function MobileImage100Width ({ alt, className, fallback, src, style }) {
    const [loading, setLoading] = useState(true)
    const [imgSrc, setImgSrc] = useState(null)
    const [ok, setOk] = useState(true)
    const router = useRouter()

    useEffect(() => {
        if (imgSrc !== src && imgSrc !== fallback) {
            setImgSrc(src)
        }
    }, [fallback, ok, imgSrc, router, src])

    const onLoad = () => setLoading(false)
    const onError = () => {
        if (fallback && imgSrc !== fallback) setImgSrc(fallback)
        else setOk(false)
    }

    return ok
        ? <img
            alt={alt}
            className={className}
            onLoad={onLoad}
            onError={onError}
            src={imgSrc}
            style={_style(loading, style)}
          />

        : null
}