import { useEffect, useRef } from 'react'

const DEBOUNCE = 600

export const usePush = (pathname) => {
	const ref = useRef()

	useEffect(() => {
		if (!window) return

		function clear() {
			clearTimeout(ref.current)
		}

		function timeout(_f) {
			clear()
			ref.current = setTimeout(_f, DEBOUNCE)
		}

		function set() {
			const gads = window.adsbygoogle
			if (!gads || !gads.loaded) return timeout(set)
			try {
				gads.push({})
			} catch (err) {
				console.log(err)
			}
		}

		timeout(set)

		return clear
	}, [pathname])
}
