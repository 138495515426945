"use client"

import { Children, createElement,
    createContext, useContext, useEffect, useMemo, useRef, useState, useCallback } from 'react'
import Image from "next/legacy/image"
import Maybe from '@components/layout/Maybe'

import styles from './FrontPageSlider.module.css'
import mix from '@utils/styles/mix'

class SliderState {
    activeSlide
    slideCount

    constructor (activeSlide, slideCount) {
        this.activeSlide = activeSlide
        this.slideCount = slideCount
    }
}

const SetSliderStateContext = createContext((_slideState) => null)
const GetSliderStateContext = createContext(new SliderState())
const useSliderState = () => {
    const { activeSlide, slideCount } = useContext(GetSliderStateContext)
    const setSliderState = useContext(SetSliderStateContext)
    return { activeSlide, slideCount, setSliderState }
}

const controlArrowImageProps = {
    height: 10,
    width: 6,
    src: '/icons/arrow-gray.svg',
}

let slideTickTimeoutId // <- move into inner state for multiple instances on same page

function Slider ({ children, tick, ...rest }) {
    const { activeSlide, slideCount, setSliderState } = useSliderState()
    const sliderRef = useRef()
    const slideRefs = useRef([])
    const hasMultipleSlides = slideCount > 1
    const slides = useMemo(() => addRefsToEachChild(children, slideRefs), [children])

    const resetOnResize = useCallback((_) => setSliderState(new SliderState(0, slideCount)),
        [slideCount, setSliderState])

    useEffect(() => {
        window.addEventListener('resize', resetOnResize)
        return () => window.removeEventListener('resize', resetOnResize)
    }, [resetOnResize])

    useEffect(() => {
        const slideChildCount = Children.count(children)
        if (slideCount != slideChildCount) {
            setSliderState(
                new SliderState(activeSlide, slideChildCount))
        }
    }, [activeSlide, children, setSliderState, slideCount])

    useEffect(() => {
        const slideElement = slideRefs.current[activeSlide]
        const slideWidth = slideElement.clientWidth
        sliderRef.current.scrollLeft = slideWidth * activeSlide
    }, [activeSlide])

    useEffect(() => {
        if (tick == null || !hasMultipleSlides) return
        slideTickTimeoutId = setTimeout(() => {
            const newIdx = activeSlide + 1
            setSliderState(new SliderState(newIdx < slideCount ? newIdx : 0, slideCount))
        }, tick)
        return () => clearTimeout(slideTickTimeoutId)
    }, [activeSlide, hasMultipleSlides, setSliderState, slideCount, tick])

    return <div className={styles.wrapper}>
        <Maybe.Desktop>
            { hasMultipleSlides &&
                <LeftControl className={mix([styles.control, styles['left-control']])} /> }

            { hasMultipleSlides &&
                <RightControl className={mix([styles.control, styles['right-control']])} /> }
        </Maybe.Desktop>

        <div className={styles.slider} ref={sliderRef} {...rest}>
            { slides }
        </div>
    </div>
}

function SliderStateProvider ({ children }) {
    const [sliderState, setSliderState] = useState(new SliderState(0))
    return (
        <SetSliderStateContext.Provider value={setSliderState}>
            <GetSliderStateContext.Provider value={sliderState}>
                {children}
            </GetSliderStateContext.Provider>
        </SetSliderStateContext.Provider>
    )
}

function Slide ({ children, forwardRef, ...rest }) {
    return <div className={styles.slide} ref={forwardRef} {...rest}>
        {children}
    </div>
}

function LeftControl ({ ...rest }) {
    const { activeSlide, slideCount, setSliderState } = useSliderState()
    const prev = () => {
        clearTimeout(slideTickTimeoutId)
        const newIdx = activeSlide - 1
        setSliderState(new SliderState(newIdx < 0 ? slideCount - 1 : newIdx, slideCount))
    }

    return <button onClick={prev} {...rest}>
        <Image {...controlArrowImageProps}
            alt="Poprzedni slajd"
        />
    </button>
}

function RightControl ({ ...rest }) {
    const { activeSlide, slideCount, setSliderState } = useSliderState()
    const next = () => {
        clearTimeout(slideTickTimeoutId)
        const newIdx = activeSlide + 1
        setSliderState(new SliderState(newIdx < slideCount ? newIdx : 0, slideCount))
    }

    return <button onClick={next} {...rest}>
        <Image {...controlArrowImageProps}
            alt="Następny slajd"
        />
    </button>
}

function DotControls ({ ...rest }) {
    const { activeSlide, slideCount, setSliderState } = useSliderState()
    if (slideCount < 2) return null

    const setIdx = (idx) => () => {
        clearTimeout(slideTickTimeoutId)
        setSliderState(new SliderState(idx, slideCount))
    }

    return <div className={styles['dot-controls']} {...rest}>
        { arrayOfLength(slideCount).map((_, idx) =>
            <button className={mix([styles.control, styles['dot-control'], idx === activeSlide && styles['active']])}
                key={idx}
                onClick={setIdx(idx)} />)
        }
    </div>
}

function addRefsToEachChild (children, slideRefs) {
    if (!children) return null
    return Children.map(children, (child, idx) => {
        if (!child) return null
        return createElement(child.type, {
            ...child.props,
            forwardRef: (element) => slideRefs.current[idx] = element,
        })
    })
}

function arrayOfLength (length) {
    return Array.from({ length }, (_, i) => i)
}

Slider.Slide = Slide
Slider.LeftControl = LeftControl
Slider.RightControl = RightControl
Slider.DotControls = DotControls
Slider.StateProvider = SliderStateProvider

export default Slider
export {
    Slide,
    Slider,
    LeftControl,
    RightControl,
    DotControls,
    SliderStateProvider,
}