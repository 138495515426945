"use client"

import { usePathname } from 'next/navigation'
import Script from 'next/script'
import Maybe from '@components/layout/Maybe'
import { usePush } from './usePush'

const ID = 'main-ads'
const STYLE = {
	display: 'flex',
	justifyContent: 'center',
	marginTop: 40,
}

function AdsenseMain() {
	const pathname = usePathname()

	usePush(pathname)

	return (
		<>
			<Maybe.Mobile>
				<Script
					async
					key={`${ID}-${pathname}`}
					src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9600016495992077"
					crossorigin="anonymous"
				/>
				<ins
					className="adsbygoogle"
					key={`${ID}-ins-${pathname}`}
					style={STYLE}
					data-ad-client="ca-pub-9600016495992077"
					data-ad-slot="7442121452"
					data-ad-format="auto"
					data-full-width-responsive="true"
				/>
			</Maybe.Mobile>

			<Maybe.Desktop>
				<Script
					async
					key={`${ID}-desktop-${pathname}`}
					src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9600016495992077"
					crossorigin="anonymous"
				/>
				<ins
					className="adsbygoogle"
					key={`${ID}-ins-desktop-${pathname}`}
					style={STYLE}
					data-ad-client="ca-pub-9600016495992077"
					data-ad-slot="5857577042"
					data-ad-format="auto"
					data-full-width-responsive="true"
				/>
			</Maybe.Desktop>
		</>
	)
}

export { AdsenseMain }
