import { useCallback, useEffect, useState } from 'react'

const useWindowWidth = () => {
    const [width, setWidth] = useState(0)

    const updateWidth = useCallback((_) => {
        setWidth(document.documentElement.clientWidth
            || window.innerWidth)
    }, [])

    useEffect(() => {
        updateWidth()
        window.addEventListener('resize', updateWidth)
        return () => window.removeEventListener('resize', updateWidth)
    }, [updateWidth])

    return width
}

export default useWindowWidth